import Item from "../types/Item";
export default class ApiService {

    async fetchData(domain: string, path: string, options: any = {}): Promise<Response> {
        const server = domain.replace(/\/$/g, '')
        return fetch(`${server}${path}`, options);
    }
  
    async getItems(domain: string): Promise<Item[]> {
        const response = await this.fetchData(domain, `/items/`);
        if (response.ok) {
            const data = await response.json();
            const items = data.data;
            return items as Item[]
        }
        return Promise.reject(response.status);   
    }
}