import React, { useState, useEffect } from "react";
import Item from "../types/Item";
import ItemView from "./ItemView";
import "./List.scss";
import { useApi } from '../providers/ApiProvider';

type Props = {};

export default function List(props: Props) {
  const getServer = () => {
    return 'https://d4y4n4qygc.execute-api.us-west-2.amazonaws.com/v1'; 
  }

  const api = useApi();
  const [items, setItems] = useState<Array<Item>>([]);

  useEffect(() => {
    async function getItems() {
        const items = await api.getItems(getServer());
        setItems(items);
    }
    getItems();
 }, [])

  return (
    <div className="list">
      {items.map((item, index) => {
        return (
          <div key={index} className="list-item">
            <ItemView item={item}></ItemView>
          </div>
        );
      })}
    </div>
  );
}
