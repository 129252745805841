import React from "react";
import Header from "./Header";
import List from "./List";

type Props = {};

export default function Spa(props: Props) {
  return (
    <div>
      <div className="container grey">
        <Header title="SPA" subtitle="Deployment Pipeline" />
      </div>
      <List />
   </div>
  );
}
