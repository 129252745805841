import React from 'react';
import ApiService from 'services/ApiService';

export const ApiContext = React.createContext<ApiService | undefined>(undefined);

export function useApi() {
  const context = React.useContext(ApiContext);
  if (context === undefined) {
    throw new Error('useApi must be used within an TodoApiProvider');
  }
  return context;
}

export default function ApiProvider({ children }: { children: React.ReactNode }) {
  return <ApiContext.Provider value={new ApiService()}>{children}</ApiContext.Provider>;
}