import React from "react";
import Item from "../types/Item";
import Input from "@paprika/input";
import "./ItemView.scss";

type Props = {
    item: Item
};



export default function ItemView({item}: Props) {
  return (
    <div className="item">
      <div className="input">
        <Input isReadOnly={true} placeholder="Input text" size="medium" value={item.name}/>
      </div>
    </div>
  );
}
